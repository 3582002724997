import React from 'react';
import data from "../data/learningcenter.json";
import Collapsible from '../components/Collapsible';
import {
  LearningCenterContainer,
  LearningCenterHeader,
  LearningCenterSection,
  HorizontalDivider
} from '../styles/LearningCenterStyle';
  
const LearningCenter = () => {
  return (
    <LearningCenterContainer>
      <LearningCenterHeader>{data.header}</LearningCenterHeader>
      <br/>
      {data.sections.map((section, i) => (
        <LearningCenterSection key={i}>
            {i === 0 && <HorizontalDivider />}
            <Collapsible
                open={false}
                title={section.title}
            >
                {section.body}
            </Collapsible>
            <HorizontalDivider />
        </LearningCenterSection>
      ))}
    </LearningCenterContainer>
  );
};
  
export default LearningCenter;