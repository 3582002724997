import React from 'react';
import data from "../data/contactus.json";
import ContactUsButton from "../components/ContactUsButton";
import {
  ContactUsContainer
} from '../styles/ContactUsStyle';
  
const ContactUs = () => {
  return (
    <ContactUsContainer>
      <ContactUsButton copyText={data.button.email} title={data.button.title} />
       {/* <ContactUsButton title={data.button.title} email={data.button.email} >{data.button.title}</ContactUsButton> */}
    </ContactUsContainer>
  );
};
  
export default ContactUs;