import React from "react";
import {
  BrandmarkImg,
  NavLink,
  NavMenu,
  NavDivider
} from '../../styles/SharedStyle';
import data from "../../data/navmenu.json";
import brandmark from '../../assets/brandmark.png';
    
const Navbar = () => {
  return (
    <>
      <BrandmarkImg src={brandmark} alt='' />
      <NavMenu>
        {data.map((item, i) => {
          const path = item.replace(/\s+/g, '');
          return (<>
            <NavLink
              key={item}
              to={'/' + path}
            >
              {item}
            </NavLink>
            {i < (data.length - 1) ? <NavDivider key={i} className="vertical"/> : null}
          </>);
        })}
      </NavMenu>
    </>
  );
};
  
export default Navbar;