import React, { useState } from 'react';
import data from "../data/legal.json";
import {
  LegalContainer,
  MainBody,
  SideBar,
  SidebarLink,
  LegalMainSection,
  LegalMainTitle
} from '../styles/LegalStyle';
  
const Legal = () => {
  const [currMenu, setCurrMenu] = useState(data[0].key);
 
  return (
    <LegalContainer>
      <SideBar>
        {data.map((menuItem, i) => (
          <SidebarLink
            key={i}
            style={{color: menuItem.key === currMenu ? '#357978' : '#333333'}}
            onClick={() => {setCurrMenu(menuItem.key)}}
          >
            {menuItem.header}
          </SidebarLink>        
        ))}
      </SideBar>
      <MainBody>
        {currMenu && data.filter(item => item.key === currMenu)[0].sections.map((section, index) => (
          <div key={index}>
            <LegalMainTitle>{section.title}</LegalMainTitle>
            {section.body.map((paragraph, k) => (<LegalMainSection key={k}>{paragraph}</LegalMainSection>))}
          </div>
        ))}
      </MainBody>
    </LegalContainer>
  );
};
  
export default Legal;