import React from "react";
import {
  ContactButton
} from "../../styles/SharedStyle"
import { useState } from "react";

const ContactUsButton = ({ copyText, title }) => {
  const [isCopied, setIsCopied] = useState(false);

  // This is the function to copy to clipboard
  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      console.log("Not copied");
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div>
      <ContactButton onClick={handleCopyClick}>
           {isCopied ? 'Email Copied!' : title}
      </ContactButton>
    </div>
  );
}

export default ContactUsButton;