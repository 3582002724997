import React, { useState } from "react";
import {
  CollapsibleButton,
  CollapsibleContainer,
  CollapsibleSection,
  CollapsibleSubheader
} from "../../styles/SharedStyle"

const Collapsible = ({ open, children, title }) => {
  const [isOpen, setIsOpen] = useState(open);
  const [sign, setSign] = useState("+");
  
  const handleCollapsible = () => {
    setIsOpen((prev) => !prev);
    if (sign === "+") setSign("-");
    else setSign("+");
  };

  return (
    <>
      <div className="card">
        <CollapsibleContainer>
          <CollapsibleSubheader>{title}</CollapsibleSubheader>
          <CollapsibleButton type="button" className="btn" onClick={handleCollapsible}>
            {sign}
          </CollapsibleButton>
        </CollapsibleContainer>
        <div className="border-bottom">
          <div>{isOpen && <CollapsibleSection>{children}</CollapsibleSection>}</div>
        </div>
      </div>
    </>
  );
};

export default Collapsible;