import React from 'react';
import data from "../data/about.json";
import logo from '../assets/kalyani.png';
import ContactUsButton from "../components/ContactUsButton";
import {
    LogoImg
  } from '../styles/SharedStyle';
import {
    AboutContainer,
    AboutHeader,
    AboutSection
  } from '../styles/AboutStyle';
  
const About = () => {
    
    return (
        <AboutContainer>
            <LogoImg src={logo} alt='' />
            <br/>
            <AboutHeader>{data.header}</AboutHeader>
            <br/>
            <AboutSection>
                {data.sections.map(section => (
                    <>
                        <div>{section}</div>
                        <br/>
                    </>
                ))}
            </AboutSection>
            <ContactUsButton copyText={data.button.email} title={data.button.title} />
        </AboutContainer>
    );
};
  
export default About;