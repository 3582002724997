import { NavLink as Link } from 'react-router-dom';
import styled from 'styled-components';

/* 
This file contains styles for the following shared components:
 - App Container
 - Brandmark and Logo Images
 - Navbar Menu
 - Collapsible Menu
 - Contact Button
*/

// ---- App Page Container Styles ----
export const AppContainer = styled.div`
    max-width: 1400px;
    margin: auto;
    overflow-x: hidden;
`;

// ---- Images Styles ----
export const BrandmarkImg = styled.img`
  width: 40px;
    height: 59px;
    position: relative;
    top: 40px;
    left: 80px;
    
    /* Media query for screens with width smaller than 1000px */
    @media only screen and (max-width: 1000px) {
        left: 30px;
    }
`;

export const LogoImg = styled.img`
    width: 153px;
    height: 27px;
`;
 
// Navigation Bar
export const NavMenu = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 20px;
    position: relative;
    top: 40px;
    @media only screen and (max-width: 400px) {
        justify-content: flex-start;
    }
    
    @media only screen and (max-width: 500px) {
        width: 90%;
    }
`;

export const NavLink = styled(Link)`
    color: #333333;
    text-decoration: none;
    height: 20px;
    font-family: 'Open Sauce One';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.02em;
`;
  
export const NavDivider = styled.div`
    /* | */
    width: 5px;
    height: 14px;
    font-family: 'Open Sauce One';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: -0.02em;

    &.vertical {
        border-left: 1px solid;
        border-left-color: #B1C9C8;
    }
`;

// Collapsible menu
export const CollapsibleButton = styled.button`
    width: 24px;
    height: 24px;
    cursor: pointer;
    border: none;
    padding: 0;
    background: none;
    font-size: 24px;
    /* Product/Dark Charcoal */
    color: #333333;
`;

export const CollapsibleContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 20px;
`;

export const CollapsibleSubheader = styled.div`
    height: 20px;
    margin-top:24px;
    margin-bottom:24px;
    font-family: 'Open Sauce One';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    /* Product/Dark Charcoal */
    color: #333333;
`;

export const CollapsibleSection = styled.div`
    margin-top: 25px;
    font-family: 'Open Sauce One';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    letter: -2%;
    text-align: justify;
    color: #333333;
`;

// Contact Button
export const ContactButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 40px;
    gap: 8px;
    position: relative;
    width: 240px;
    height: 44px;
    border-width: 0;
    cursor: pointer;
    background: #357978;
    border-radius: 30px;
    /*  Title styles */
    font-family: 'Open Sauce One';
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    /* Product/White */
    color: #FFFFFF;

    @media only screen and (max-width: 500px) {
        width: 170px;
    }
`;
